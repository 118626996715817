<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="drawer">
        mdi-chevron-left
      </v-icon>

      <v-icon v-else>
        mdi-chevron-right
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down"
    >
      <v-icon style="color: #509dc8; margin-top: -5px;">
        {{ getRouteIcon($route.name) }}
      </v-icon>
      {{ str[$route.name] ? str[$route.name] : $route.name }}
    </v-toolbar-title>

    <v-spacer />

    <div style="display: flex; align-items: center; margin-bottom: 5px;">
      <span class="mr-2">Dark</span>
      <v-switch
        v-model="$vuetify.theme.dark"
        class="ma-0 pa-0"
        color="secondary"
        @change="onThemeChanged"
        hide-details
      />
    </div>

    <v-menu
      v-if="showChat"
      v-model="chatVisible"
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      :content-class="'chat-menu ' + (chatIsLoading ? 'chat-menu-loading': '') + (chatReady ? 'chat-menu-ready': '') + (isMobile ? ' chat-menu-mobile' : '') + (isChatNewPage ? ' chat-new-page' : '') + (isTalkjs ? ' chat-menu-talkjs': '')"
      :close-on-click="false"
      :close-on-content-click="false"
      ref="menuChat"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          id="chat-events"
          class="ml-2"
          :color="chatNewMessages ? 'warning' : 'success'"
          min-width="0"
          text
          @click="onChangeChatStatus"
        >
          <v-icon
            v-if="!chatVisible || isChatNewPage"
            v-bind:style="{'opacity': chatVisible && !isChatNewPage && !chatIsLoading ? '0.5' : ''}"
          >
            mdi-chat
          </v-icon>
          <v-icon
            v-if="chatVisible && !chatIsLoading && !menuChatDoubleClick && !isChatNewPage"
            class="vue-chat-icon-arrow"
          >
            mdi-chevron-right
          </v-icon>
          <span
            v-if="chatVisible && !chatIsLoading && menuChatDoubleClick && !isChatNewPage"
            class="warning--text"
          >
            {{ str['close'] }}
          </span>
          <v-progress-circular
            v-if="chatIsLoading"
            indeterminate
            color="secondary"
            class="chat-menu-loading-icon"
          />
          <span v-if="chatNewMessages && (!menuChatDoubleClick || isChatNewPage)">
            {{ chatNewMessages }}
          </span>
        </v-btn>
      </template>

      <v-list
        v-if="!isChatNewPage"
        :tile="false"
        nav
        :class="{'pt-0': isTalkjs}"
      >
        <div
          v-if="chatUrl && !isTalkjs"
          class="chat-menu-content"
        >
          <div
            v-if="isMobile && chatVisible && !chatIsLoading"
            class="row-align-right"
          >
            <v-btn
              color="warning"
              text
              @click="closeChat"
            >
              {{ str['close'] }}
            </v-btn>
          </div>
          <iframe
            id="chat-iframe"
            :src="chatUrl"
            :name="Date.now()"
            class="chat-menu-iframe"
            allow="geolocation; microphone; camera; notifications;"
          ></iframe>
        </div>
        <div v-if="isTalkjs && talkjsInbox">
          <Talkjs :inbox="talkjsInbox" />
        </div>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          v-if="notifsCount>0"
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>{{ notifsCount }}</span>
            </template>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <v-list-item
            @click="goPage('/home/userProfile')"
          >
            <v-list-item-title>
              {{ str['update_user_profile'] }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="user && user.type === 0"
            @click="goPage('/home/ptPaymentsHistory')"
          >
            <v-list-item-title>
              {{ str['payments'] }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="user && user.type === 0"
            @click="goPage('/home/settings')"
          >
            <v-list-item-title>
              {{ str['settings'] }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="showSupport"
            @click="goPage('/home/support')"
          >
            <v-list-item-title>
              {{ str['support'] }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="logout()"
          >
            <v-list-item-title>
              {{ str['logout'] }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<style>
  .body-chat-opened .v-application--wrap {
    width: calc(100% - 550px) !important;
    overflow: auto;
    height: 100vh;
  }

  .body-chat-opened .v-dialog__content {
    width: calc(100% - 550px) !important;
  }

  .chat-menu {
    min-width: 550px !important;
    max-width: 550px !important;
    width: 100% !important;
    top: 0 !important;
    left: initial !important;
    right: 0 !important;
    position: absolute !important;
    z-index: -99 !important;
  }

  .chat-menu-mobile {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .chat-new-page {
    min-width: 0px !important;
    max-width: 0px !important;
    width: 0px !important;
  }

  .chat-menu-talkjs {
    min-width: 400px !important;
    max-width: 400px !important;
  }

  .chat-menu-iframe {
    width: 100%;
    height: calc(100vh - 15px);
    border: none;
  }

  .chat-menu-mobile .chat-menu-iframe {
    height: calc(100vh - 140px);
  }

  .chat-menu-ready {
    position: inherit !important;
    z-index: 9999 !important;
  }

  .chat-menu-loading {
    z-index: -99 !important;
    position: absolute !important;
  }

  .chat-menu-loading-icon {
    position: absolute;
    height: 25px !important;
    width: 25px !important;
    background: #ecececa6;
  }

  .chat-menu .v-list {
    overflow: hidden;
  }

  .chat-menu .chat-menu-content {
    width: 100%;
    background-color: #fff;
  }
</style>

<script>
  import axios from 'axios'
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import Talk from 'talkjs'

  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      Talkjs: () => import('@/views/dashboard/components/talkjs/Talkjs'),
    },
    data () {
      const user = Utils.getUser()
      const isTalkjs = user && user.configurations && user.configurations.chat && user.configurations.chat.talkjs ? true : false
      return {
        str: window.strings ? window.strings : {},
        isMobile: window.isMobile,
        showChat: user && user.configurations && user.configurations.chat && Utils.hasPermission('chat_view') ? true : false,
        isChatNewPage: user && user.configurations && user.configurations.chat && user.configurations.chat.new_page ? true : false,
        isTalkjs: isTalkjs,
        showSupport: Utils.hasPermission('support_view') ? true : false,
        notifsEnabled: false,
        notifsTimer: 0,
        notifsInterval: 30000, // 5min = 5 * 60s * 1000ms = 30000 ms
        notifsCount: 0,
        notifications: [],
        user: user,
        chatReady: false,
        chatUrl: null,
        chatVisible: true,
        chatIsLoading: true,
        chatNewMessages: 0,
        menuChatDoubleClick: false,
        talkjsInbox: null,
      }
    },
    computed: {
      ...mapState(['drawer']),
    },
    watch: {
      chatVisible: function (val) {
        if (val) {
          this.chatReady = true
          if (!this.isChatNewPage) {
            document.body.classList.add('body-chat-opened')
          }
        } else {
          if (!this.isChatNewPage) {
            document.body.classList.remove('body-chat-opened')
          }
        }
      }
    },
    beforeMount: function () {
      this.setChatUrl()
      this.startNotificationsTimer()
      this.activeChatEvents()
      this.activeChatNewPage()
      this.initTalkjs()
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      getRouteIcon (route) {
        let icon = ''
        switch (route) {
          case 'dashboard':
            icon = 'mdi-view-dashboard'
            break
          case 'clients':
            icon = 'mdi-account-group'
            break
          case 'training_plans_base':
            icon = 'mdi-content-paste'
            break
          case 'pdf_training_plans_base':
            icon = 'mdi-file-pdf-box'
            break
          case 'plans_base':
            icon = 'mdi-file-pdf-box'
            break
          case 'trains_base':
            icon = 'mdi-content-paste'
            break
          case 'exercises':
            icon = 'mdi-dumbbell'
            break
          case 'classes_exercises':
            icon = 'mdi-dumbbell'
            break
          case 'nutrition_base':
            icon = 'mdi-notebook-multiple'
            break
          case 'employees':
            icon = 'mdi-account-hard-hat'
            break
          case 'payments_packs':
            icon = 'mdi-account-credit-card'
            break
          case 'promotional_codes':
            icon = 'mdi-clipboard-list-outline'
            break
          case 'partnerships':
            icon = 'mdi-handshake'
            break
          case 'contents':
            icon = 'mdi-table-of-contents'
            break
          case 'supplements':
            icon = 'mdi-blender'
            break
          case 'streaming':
            icon = 'mdi-video'
            break
          case 'notifications':
            icon = 'mdi-bell-ring'
            break
          case 'lives':
            icon = 'mdi-video-account'
            break
          case 'chat':
            icon = 'mdi-chat'
            break
          case 'recipes':
            icon = 'mdi-nutrition'
            break
          case 'foods':
            icon = 'mdi-food-variant'
            break
          case 'nutrition_plans_base':
            icon = 'mdi-notebook-multiple'
            break
          case 'diets_base':
            icon = 'mdi-notebook-multiple'
            break
          case 'statistics':
            icon = 'mdi-finance'
            break
          case 'calendar':
            icon = 'mdi-calendar'
            break
          case 'mkt_clint':
            icon = 'mdi-chart-box-outline'
            break
          case 'tutorials':
            icon = 'mdi-cast-education'
            break
          case 'scouting':
            icon = 'mdi-tab-search'
            break
          case 'tasks':
            icon = 'mdi-calendar-check'
            break
        }

        return icon
      },
      goPage (page) {
        if (this.$route.path !== page) {
          this.$router.push({ path: page })
        }
      },
      logout () {
        const self = this
        this.$isLoading(true)
        Api.logout(function () {
          if (self.showChat && !self.isTalkjs) {
            self.sendEventToChat({
              event: 'destroy'
            })
            setTimeout(function () {
              self.$isLoading(false)
              self.resetDashboard()
            }, 500)
          } else {
            self.$isLoading(false)
            self.resetDashboard()
          }
        })
      },
      resetDashboard () {
        this.stopNotificationsTimer()
        Utils.clearStorage()
        this.$router.replace({ path: '/' })
      },
      startNotificationsTimer () {
        if (this.notifsEnabled) {
          // Starts notifications timer
          this.notifsTimer = setInterval(this.onNotifsTimerElapsed, this.notifsInterval)
        }
      },
      stopNotificationsTimer () {
        if (this.notifsEnabled) {
          // Stops notifications timer
          clearInterval(this.notifsTimer)
        }
      },
      onNotifsTimerElapsed: function () {
        const self = this
        // Get notifications from server
        Api.getNotifications(axios.defaults.userDbId, function (response) {
          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              const notification = response.data[i]
              self.notifications.push(notification.body)
              self.notifsCount = self.notifications.length
            }
          }
        })
      },
      setChatUrl: function () {
        if (this.showChat && !this.isTalkjs) {
          this.chatUrl = axios.defaults.chatWebviewUrl +
            'is_pt=true&theme=light&audio=true' +
            '&pt_id=' + axios.defaults.ptId +
            '&id=' + axios.defaults.ptId +
            '&log_id=' + axios.defaults.userId +
            '&log_email=' + axios.defaults.chatLogEmail +
            '&log_name=' + axios.defaults.chatLogName +
            (this.isChatNewPage ? '&new_page=true' : '') +
            (Utils.hasPermission('chat_delete') ? '&delete_room=true' : '') +
            '&dashboard_client_url=true&lng=' + window.language +
            '&timestamp=' + new Date().getTime()
        }
      },
      onChangeChatStatus: function () {
        const self = this
        if (this.isTalkjs) {
          if (this.chatVisible) {
            this.closeChat()
          } else {
            this.openChat()
          }
          return true
        }
        if (this.isChatNewPage) {
          return this.openChat()
        }
        if (this.chatVisible) {
          if (this.menuChatDoubleClick) {
            this.menuChatDoubleClick = false
            this.closeChat()
            return true
          }
          this.menuChatDoubleClick = true
          setTimeout(function () {
            self.menuChatDoubleClick = false
          }, 3000)
        } else {
          this.openChat()
        }
      },
      closeChat: function () {
        this.chatVisible = false
        this.onChatMenuState()
      },
      openChat: function () {
        this.chatVisible = true
        this.openChatNewPage()
        this.onChatMenuState()
      },
      onChatMenuState: function () {
        if (this.isTalkjs) {
          this.talkjsInbox.setPresence({ visible: this.chatVisible }) 
        } else {
          if (this.chatVisible) {
            this.sendEventsToShowChat()
          } else {
            this.sendEventsToHideChat()
          }
        }
      },
      sendEventsToShowChat: function () {
        this.sendEventToChat({
          event: 'change-chat-visible',
          data: true
        })
      },
      sendEventsToHideChat: function () {
        const self = this
        if (!this.isChatNewPage) {
          setTimeout(function () {
            self.sendEventToChat({
              event: 'change-chat-visible',
              data: false
            })
            self.sendEventToChat({
              event: 'close-room'
            })
          }, 500)
        }
      },
      activeChatEvents: function () {
        const self = this

        if (this.showChat) {
          window.addEventListener('message', function (event) {
            try {
              if (event && event.data && event.data.indexOf('setImmediate') !== 0) {
                const data = JSON.parse(event.data)
  
                if (data && data.event === 'open-client-trigger' && data.data) {
                  self.$isLoading(true)
                  getUser(function (user) {
                    if (user) {
                      const client = Utils.encodeClient(user)
                      Utils.setStorage('client', client)
                      window.showPaydayAlert = true
                      window.clientPageCacheList = null
                      if (self.$route.path === '/home/client') {
                        self.$router.replace({ path: '/home/empty', query: { tab: 0 } })
                        setTimeout(function () {
                          self.$router.replace({ path: '/home/client', query: { tab: 'details' } })
                        }, 500)
                      } else {
                        self.$router.push({ path: '/home/client' })
                      }
                    } else {
                      self.$alert(
                        window.strings['client_not_found'],
                        '',
                        'warning',
                        Utils.getAlertOptions()
                      )
                    }
                  })

                  function getUser(cb) {
                    if (data.data && data.data.chat_id) {
                      Api.getUserWithChatId(data.data.chat_id, function (response) {
                        if (response.success && response.data[0]) {
                          self.$isLoading(false)
                          return cb(response.data[0])
                        }
                        getUserWithEmail()
                      })
                    } else {
                      getUserWithEmail()
                    }

                    function getUserWithEmail() {
                      if (data.data && data.data.email) {
                        Api.getUserWithEmail(data.data.email, function (response) {
                          self.$isLoading(false)
                          if (response.success) {
                            cb(response.data[0])
                          } else {
                            self.$alert(
                              response.message,
                              '',
                              'warning',
                              Utils.getAlertOptions()
                            )
                          }
                        })
                      } else {
                        self.$isLoading(false)
                        cb(null)
                      }
                    }
                  }
                }

                if (data && data.event === 'open-client-register' && data.data) {
                  self.$isLoading(true)
                  Api.getClientRegisterWithChatId({
                    chat_id: data.data,
                    fields: ['id', 'status']
                  }, function (response) {
                    self.$isLoading(false)
                    if (response.success) {
                      if (response.data[0]) {
                        const registerStatusList = Utils.getRegisterStatus()
                        let openRegisterMessageError = null
                        if (registerStatusList && registerStatusList.length) {
                          const registerStatus = registerStatusList.find(function (st) {
                            return st.value === response.data[0].status
                          })
                          if (registerStatus && registerStatus.open_message_error) {
                            openRegisterMessageError = registerStatus.open_message_error
                          }
                        }
                        if (openRegisterMessageError) {
                          self.$alert(
                            openRegisterMessageError,
                            '',
                            'warning',
                            Utils.getAlertOptions()
                          )
                        } else {
                          if (self.$route.path === '/home/dashboard') {
                            window.postMessage(JSON.stringify({
                              event: 'dashboard-notification',
                              data: {
                                id: data.event,
                                data: {
                                  id: response.data[0].id
                                }
                              }
                            }), '*')
                          } else {
                            self.$router.push({ path: '/home/dashboard', query: { register_id: response.data[0].id }  })
                          }
                        }
                      } else {
                        self.$alert(
                          window.strings['client_not_found'],
                          '',
                          'warning',
                          Utils.getAlertOptions()
                        )
                      }
                    } else {
                      self.$alert(
                        response.message,
                        '',
                        'warning',
                        Utils.getAlertOptions()
                      )
                    }
                  })
                }

                if (data && data.event === 'new-messages') {
                  if (self.chatIsLoading) {
                    self.chatVisible = false
                    self.chatIsLoading = false
                    setTimeout(function () {
                      self.sendEventsToHideChat()
                    }, 500)
                  }
                  self.chatNewMessages = data.data
                }

                if (data && data.data &&
                  (data.event === 'open-chat-client' || data.event === 'open-new-chat-client')
                ) {
                  self.chatVisible = true
                  if (self.isTalkjs) {
                    self.openClientTalkjs(data.data)
                  } else {
                    self.sendEventToChat(data)
                    if (!self.isChatNewPage) {
                      setTimeout(function () {
                        document.getElementsByTagName('html')[0].scrollTop = 0
                      }, 500)
                    }
                  }
                }

                if (data && data.data && data.event === 'link') {
                  window.open(data.data, '_blank')
                }
              }
            } catch (error) {}
          })
        }
      },
      sendEventToChat: function (data) {
        if (this.showChat && !this.isTalkjs) {
          try {
            if (this.isChatNewPage) {
              if (window.chatPage) {
                window.chatPage.postMessage(JSON.stringify(data), '*')
                window.chatPage.focus()
                return true
              }
            } else {
              const iframe = document.getElementById('chat-iframe')
              if (iframe && iframe.contentWindow) {
                iframe.contentWindow.postMessage(JSON.stringify(data), '*')
                return true
              }
            }
            this.$alert(
              this.str['chat_open_room_failed'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          } catch (error) {}
        }
      },
      onThemeChanged: function () {
        localStorage.setItem('dashboard_theme', this.$vuetify.theme.dark ? 'dark' : 'light')
      },
      activeChatNewPage: function () {
        if (this.showChat && !this.isTalkjs && this.isChatNewPage) {
          const width = 550
          const height = window.screen.height
          const posX = window.screen.width - width + 40
          const posY = window.screen.height
          window.chatPage = window.open(this.chatUrl, '_blank', `width=${width},height=${height},left=${posX},top=${posY}`)
        }
      },
      openChatNewPage: function () {
        if (this.showChat && !this.isTalkjs && this.isChatNewPage) {
          if (window.chatPage && !window.chatPage.closed) {
            window.chatPage.focus()
          } else {
            this.activeChatNewPage()
          }
        }
      },
      destroyChatPage: function () {
        if (this.showChat && !this.isTalkjs && this.isChatNewPage && window.chatPage && !window.chatPage.closed) {
          window.chatPage.close()
        }
      },
      initTalkjs: async function () {
        const self = this
        if (this.isTalkjs) {
          await Talk.ready

          const ptTag = Object.keys(PTS_IDS).find(k => PTS_IDS[k] === this.user.pt_id)?.toLowerCase()
          const userId = (axios.defaults.dev ? 'dev' : 'prod') + '_' + ptTag + '_' + this.user.id

          Api.getTalkjsToken({
            id: userId
          }, function (response) {
            if (response.success) {
              self.talkjsUser = new Talk.User({
                id: userId,
                name: self.user.name,
                welcomeMessage: null,
                locale: 'pt-BR',
                role: ptTag + '_admin'
              })
              self.talkjsSession = new Talk.Session({
                appId: response.data.app_id,
                me: self.talkjsUser,
                locale: 'pt-BR',
                token: response.data.token
              })

              self.talkjsSession.unreads.onChange(async (conversations) => {
                let totalUnreadMessages = 0
                conversations.forEach(conversation => {
                  totalUnreadMessages += conversation.unreadMessageCount;
                })
                self.chatNewMessages = totalUnreadMessages
                if (self.chatIsLoading) {
                  setTimeout(() => {
                    self.closeChat()
                    self.chatIsLoading = false
                  })
                }
              })

              self.talkjsInbox = self.talkjsSession.createInbox({
                selected: null,
                locale: 'pt-BR'
              })
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      openClientTalkjs: function (data) {
        const self = this
        if (this.isTalkjs) {
          const clientDbId = data.id ? data.id : data
          this.$isLoading(true)
          Api.getUserWithDbId({
            id: clientDbId,
            fields: ['id', 'name', 'chat_token']
          }, function (response) {
            self.$isLoading(false)
            if (response.success && response.data && response.data[0]) {
              const clientData = response.data[0]
              const ptTag = Object.keys(PTS_IDS).find(k => PTS_IDS[k] === self.user.pt_id)?.toLowerCase()
              const client = new Talk.User({
                id: (axios.defaults.dev ? 'dev' : 'prod') + '_' + ptTag + '_' + clientData.id,
                name: clientData.name,
                locale: 'pt-BR',
                role: ptTag + '_client'
              })
              const conversation = self.talkjsSession.getOrCreateConversation(
                Talk.oneOnOneId(self.talkjsUser, client)
              )
              conversation.setAttributes({
                custom: {
                  customer_token: clientData.chat_token ? clientData.chat_token : ''
                }
              })
              conversation.setParticipant(self.talkjsUser)
              conversation.setParticipant(client)
              self.talkjsInbox.select(conversation)
              self.openChat()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
    },
    beforeDestroy: function () {
      this.destroyChatPage()
    },
  }
</script>
